import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tcc-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  currentYear;

  constructor() {
    const today = new Date();
    this.currentYear = today.getFullYear();
  }

  ngOnInit() {
  }

}
