import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'tcc-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.scss']
})
export class HomeContactComponent {

  contactInfo = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

  constructor(public element: ElementRef) {
  }

}
