import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Common } from '../../utils/common';

declare const TagCanvas;

@Component({
  selector: 'tcc-home-technical',
  templateUrl: './home-technical.component.html',
  styleUrls: ['./home-technical.component.scss']
})
export class HomeTechnicalComponent implements OnInit {

  private _canvasId = 'technicalCanvas';
  private _tagCanvasConfig = {
    textFont: 'Roboto, sans-serif',
    maxSpeed: 0.1,
    minSpeed: 0.01,
    pulsateTo: 0.01,
    initial: [0.1, -0.1],
    textColour: 'white',
    outlineColour: 'white',
    outlineMethod: 'none',
    depth: 1,
    wheelZoom: false,
    shuffleTags: true,
    weight: true,
    weightFrom: 'data-weight',
    noSelect: true,
    noMouse: false,
  };

  @ViewChild('canvasContainer') canvasContainer: ElementRef;
  @ViewChild('canvasElement') canvasElement: ElementRef;

  @HostListener('window:resize', []) onResize() {
    this.updateCanvas();
  }

  constructor(public element: ElementRef) {
  }

  ngOnInit() {
    this.updateCanvas();
  }

  private updateCanvas() {
    const canvasWidth = this.canvasContainer.nativeElement.offsetWidth;
    let canvasHeight = this.canvasContainer.nativeElement.offsetWidth / 2.5;

    if (canvasHeight < 450) {
      canvasHeight = 450;
    }

    this._tagCanvasConfig.weight = canvasWidth > 767;

    this.canvasElement.nativeElement.setAttribute('width', canvasWidth);
    this.canvasElement.nativeElement.setAttribute('height', canvasHeight);

    this._tagCanvasConfig.noMouse = Common.isMobile;

    // noinspection TypeScriptUnresolvedFunction
    TagCanvas.Start(this._canvasId, 'technicals', this._tagCanvasConfig);
  }

}
