import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { HomeAboutComponent } from './components/home-about/home-about.component';
import { HomeServiceComponent } from './components/home-service/home-service.component';
import { HomePortfolioComponent } from './components/home-portfolio/home-portfolio.component';
import { HomeTeamComponent } from './components/home-team/home-team.component';
import { HomeContactComponent } from './components/home-contact/home-contact.component';
import { OwlCarouselDirective } from './directives/owl-carousel.directive';
import { HomeTechnicalComponent } from './components/home-technical/home-technical.component';
import { FormsModule } from '@angular/forms';
import { FormControlModule } from 'ngx-form-control';
import { HamburgerButtonComponent } from './components/hamburger-button/hamburger-button.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HomeSliderComponent,
    HomeAboutComponent,
    HomeServiceComponent,
    HomePortfolioComponent,
    HomeTeamComponent,
    HomeContactComponent,
    OwlCarouselDirective,
    HomeTechnicalComponent,
    HamburgerButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FormControlModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
