import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const AOS;

@Component({
  selector: 'tcc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private _aosEvent;

  constructor(private _router: Router) {
    this.initAOS();
  }

  private initAOS() {
    AOS.init();

    this._aosEvent = this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        AOS.refreshHard();
      }
    });
  }
}
