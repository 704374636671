import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

declare const $;

@Component({
  selector: 'tcc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  readonly idHomeAbout = 'homeAbout';
  readonly idHomeService = 'homeService';
  readonly idHomePortfolio = 'homePortfolio';
  readonly idHomeTechnical = 'homeTechnical';
  readonly idHomeTeam = 'homeTeam';
  readonly idHomeContact = 'homeContact';

  private _documentElement = $(document);

  isFixed = false;
  isOpen = false;

  @Input() activeId = '';
  @Output() scrollTo: EventEmitter<string> = new EventEmitter();

  @HostListener('window:scroll', []) onResize() {
    this.updateHeaderPosition();
  }

  get fixedHeight() {
    const width = this._documentElement.width();

    if (width > 991) {
      return 125;
    }

    if (width > 767) {
      return 90;
    }

    return 60;
  }

  ngOnInit(): void {
    this.updateHeaderPosition();
  }

  scroll(event, id: string = null) {
    event.preventDefault();
    this.isOpen = false;
    this.scrollTo.emit(id);
  }

  get isActiveHome() {
    return !this.activeId;
  }

  get isActiveHomeAbout() {
    return this.idHomeAbout === this.activeId;
  }

  get isActiveHomeService() {
    return this.idHomeService === this.activeId;
  }

  get isActiveHomePortfolio() {
    return this.idHomePortfolio === this.activeId;
  }

  get isActiveHomeTechnical() {
    return this.idHomeTechnical === this.activeId;
  }

  get isActiveHomeTeam() {
    return this.idHomeTeam === this.activeId;
  }

  get isActiveHomeContact() {
    return this.idHomeContact === this.activeId;
  }

  toggleMenu(isOpen) {
    this.isOpen = isOpen;
  }

  private updateHeaderPosition() {
    const currentTop = this._documentElement.scrollTop();
    this.isFixed = currentTop > 70;
  }

}
