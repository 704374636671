import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HomeAboutComponent } from '../../components/home-about/home-about.component';
import { HomeServiceComponent } from '../../components/home-service/home-service.component';
import { HomePortfolioComponent } from '../../components/home-portfolio/home-portfolio.component';
import { HomeTechnicalComponent } from '../../components/home-technical/home-technical.component';
import { HomeTeamComponent } from '../../components/home-team/home-team.component';
import { HomeContactComponent } from '../../components/home-contact/home-contact.component';
import { HeaderComponent } from '../../components/header/header.component';

declare const $;

@Component({
  selector: 'tcc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private _bodyElement = $('html, body');
  private _documentElement = $(document);

  private _elementsFromTo = null;

  currentElementId = '';

  @ViewChild('header') header: HeaderComponent;
  @ViewChild('homeAbout') homeAbout: HomeAboutComponent;
  @ViewChild('homeService') homeService: HomeServiceComponent;
  @ViewChild('homePortfolio') homePortfolio: HomePortfolioComponent;
  @ViewChild('homeTechnical') homeTechnical: HomeTechnicalComponent;
  @ViewChild('homeTeam') homeTeam: HomeTeamComponent;
  @ViewChild('homeContact') homeContact: HomeContactComponent;

  @HostListener('window:scroll', []) onResize() {
    this.initHeights();
    this.updateCurrentElementId();
  }

  ngOnInit(): void {
    this.initHeights();
    this.updateCurrentElementId();
  }

  initHeights() {
    this._elementsFromTo = {
      homeAbout: this.getViewChildFromTo('homeAbout'),
      homeService: this.getViewChildFromTo('homeService'),
      homePortfolio: this.getViewChildFromTo('homePortfolio'),
      homeTechnical: this.getViewChildFromTo('homeTechnical'),
      homeTeam: this.getViewChildFromTo('homeTeam'),
      homeContact: this.getViewChildFromTo('homeContact'),
    };
  }

  scrollTo(id) {
    const element = this.getViewChildElement(id);
    const top = element ? $(element).offset().top - this.header.fixedHeight : 0;
    const currentTop = this._bodyElement.offset().top;
    const time = 500 + Math.abs(currentTop - top) / 10;

    this._bodyElement.animate({
      scrollTop: (top + 1) + 'px',
    }, time);
  }

  private getViewChildElement(id) {
    switch (id) {
      case 'homeAbout':
        return this.homeAbout.element.nativeElement;
      case 'homeService':
        return this.homeService.element.nativeElement;
      case 'homePortfolio':
        return this.homePortfolio.element.nativeElement;
      case 'homeTechnical':
        return this.homeTechnical.element.nativeElement;
      case 'homeTeam':
        return this.homeTeam.element.nativeElement;
      case 'homeContact':
        return this.homeContact.element.nativeElement;
      default:
        return null;
    }
  }

  private getViewChildFromTo(id) {
    let element = this.getViewChildElement(id);

    if (!element) {
      return {from: 0, to: 0};
    }

    element = $(element);
    const elementTop = element.offset().top - this.header.fixedHeight;
    const elementBottom = elementTop + element.outerHeight();

    return {from: elementTop, to: elementBottom};
  }

  private updateCurrentElementId() {
    const currentTop = this._documentElement.scrollTop();

    for (const elementId in this._elementsFromTo) {
      if (this._elementsFromTo.hasOwnProperty(elementId)) {
        const elementFromTo = this._elementsFromTo[elementId];
        if (currentTop >= elementFromTo.from && currentTop < elementFromTo.to) {
          this.currentElementId = elementId;
          return;
        }
      }
    }

    this.currentElementId = '';
  }

}
