import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tcc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Output() scrollTo: EventEmitter<string> = new EventEmitter();

  scroll(event, id: string = null) {
    event.preventDefault();
    this.scrollTo.emit(id);
  }

}
