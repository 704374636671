import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'tcc-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.scss']
})
export class HomeAboutComponent {

  constructor(public element: ElementRef) {
  }

}
