import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'tcc-hamburger-button',
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss']
})
export class HamburgerButtonComponent {

  @Input() set isOpen(value: boolean) {
    this.isClassOpen = value;
  }

  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.open') isClassOpen: boolean;

  @HostListener('click', []) onClick() {
    this.isClassOpen = !this.isClassOpen;
    this.toggle.emit(this.isClassOpen);
  }

}
