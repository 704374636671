import { Component, ElementRef } from '@angular/core';
import { Common } from '../../utils/common';

@Component({
  selector: 'tcc-home-team',
  templateUrl: './home-team.component.html',
  styleUrls: ['./home-team.component.scss']
})
export class HomeTeamComponent {

  configs = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      576: {
        items: 2,
        slideBy: 2,
      },
      768: {
        items: 3,
        slideBy: 3,
      },
      992: {
        items: 4,
        slideBy: 4,
      },
      1200: {
        items: 5,
        slideBy: 5,
      },
      1600: {
        items: 6,
        slideBy: 6,
      },
    }
  };

  constructor(public element: ElementRef) {
  }

  // noinspection JSMethodCanBeStatic
  get isMobile() {
    return Common.isMobile;
  }

}
