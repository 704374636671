import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'tcc-home-service',
  templateUrl: './home-service.component.html',
  styleUrls: ['./home-service.component.scss']
})
export class HomeServiceComponent {

  currentService = 1;

  constructor(public element: ElementRef) {
  }

  get isCreativeIdea() {
    return 1 === this.currentService;
  }

  get isAwesomeSupport() {
    return 2 === this.currentService;
  }

  get isProfessionalDesign() {
    return 3 === this.currentService;
  }

  get isWebsite() {
    return 4 === this.currentService;
  }

  get isMobile() {
    return 5 === this.currentService;
  }

  get isCleanCode() {
    return 6 === this.currentService;
  }

  updateCurrentService(index) {
    this.currentService = index !== this.currentService ? index : 0;
  }

}
